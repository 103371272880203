import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import Image from "../image"

const Project = props => {
  // let { post } = props
  let {
    post,
    post: {
      acf_project_fields: { place },
      taxSectors: categories,
      acf_orientation: { ...orientation },
    },
  } = props

  let cats = ""
  if (categories) {
    cats = [
      ...new Set(categories.nodes.map(q => q.slug)),
      orientation.orientation,
    ]
    cats = cats.join(" ")
  }

  let [caseStudy, setCaseStudy] = useState(0)

  function gotoCasestudy(uri, id) {
    if (typeof window !== `undefined`) {
      // window.scrollTo({ top: 0, behavior: "auto" })
      window.scrollTo({ top: 0 })
    }
    setCaseStudy(id)
    setTimeout(() => navigate(uri), 500)
  }

  /* useEffect(() => {
    console.log("mounted")
  }, []) */

  return (
    <Link
      to={post.uri}
      className={`post featured ${cats} ${
        caseStudy === post.databaseId ? "selected" : ""
      }`}
      key={post.databaseId}
      title={post.title}
      onClick={e => {
        e.preventDefault()
        gotoCasestudy(post.uri, post.databaseId)
      }}
    >
      <h2>
        {post.title}
        <br />
        <span>{place}</span>
      </h2>
      <Image data={post.featuredImage} placeholderClassName="placeholder" />
    </Link>
  )
}

export default Project
