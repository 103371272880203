import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Projects from "./projects"

const MasterPlanning = props => (
  <StaticQuery
    query={graphql`
      {
        allWpProject(
          sort: { fields: contentType___node___menuPosition, order: DESC }
          filter: {
            taxServices: {
              nodes: { elemMatch: { slug: { eq: "master-planning" } } }
            }
          }
        ) {
          nodes {
            title
            content
            uri
            databaseId
            acf_project_fields {
              place: location
            }
            featuredImage {
              node {
                ...fragmentImage
              }
            }
            acf_orientation {
              orientation
            }
            taxServices {
              nodes {
                slug
                name
              }
            }
            taxSectors {
              nodes {
                slug
                name
              }
            }
            acf_gallery {
              __typename
              gallery {
                __typename
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let {
        allWpProject: { nodes: posts },
      } = data
      // console.log(posts)
      return (
        <>
          <Projects
            posts={posts}
            breadcrumb={posts[0]?.taxServices?.nodes[0]}
            selectedFilter="featured"
            title={props.title}
          />
        </>
      )
    }}
  ></StaticQuery>
)

export default MasterPlanning
