import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Project from "./project"

const Isotope = typeof window !== `undefined` ? require("isotope-layout") : null
const imagesLoaded = require("imagesloaded")

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.onFilterChange = this.onFilterChange.bind(this)

    this.state = {
      selectedFilter: this.props.selectedFilter,
    }
  }

  handleHash = () => {
    if (typeof window !== `undefined`) {
      let clean_uri = window.location.hash.substring(1)
      if (
        clean_uri !== "" &&
        this.props.breadcrumb.slug !== "master-planning"
      ) {
        this.props = { ...this.props, selectedFilter: clean_uri }
        // console.log(this.props.breadcrumb.slug)
        /* this.state = {
          selectedFilter: this.props.selectedFilter,
        } */
        // this.setState({ selectedFilter: this.props.selectedFilter })
        /* this.setState({
          selectedFilter: this.props.selectedFilter,
        }) */
      }
    }
  }

  componentDidMount() {
    this.handleHash()

    // console.log(this.state.selectedFilter)

    this.onFilterChange(this.props.selectedFilter)
    imagesLoaded(".placeholder", () => {
      this.iso.layout()
      this.iso.arrange({ filter: `.${this.props.selectedFilter}` })
    })

    if (typeof window !== `undefined`) {
      let el = document.getElementsByTagName("html")[0]
      el.classList.add("projects")
    }
  }

  componentDidUpdate() {
    // this.handleHash()
    imagesLoaded(".placeholder", () => {
      this.iso.layout()
      this.iso.arrange({ filter: `.${this.state.selectedFilter}` })
    })
  }

  onFilterChange = newFilter => {
    if (this.iso === undefined) {
      this.iso = new Isotope(".grid-items", {
        layoutMode: "masonry",
        masonry: {
          columnWidth: ".grid-sizer",
          gutter: 0,
        },
        itemSelector: ".post",
        percentPosition: true,
      })
    }

    this.setState({
      selectedFilter: newFilter,
    })
  }

  render() {
    const { posts, breadcrumb } = this.props

    // console.log(breadcrumb)

    return (
      <div id="casestudies">
        <div id="filters">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="heading">
                  {this.props.title && parse(this.props.title)}
                </h1>
              </div>
              {this.props.breadcrumb.slug !== "master-planning" && (
                <div className="col-md-12">
                  <ProjectsFilters
                    selectedFilter={this.state.selectedFilter}
                    onFilterChange={this.onFilterChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className={`row grid grid-items ${this.state.selectedFilter}`}>
            <div className="grid-sizer portrait landscape"></div>
            {!!posts &&
              posts.map((post, index) => {
                // console.log(post.taxServices)
                return (
                  <Project
                    key={post.databaseId}
                    breadcrumb={breadcrumb}
                    post={post}
                    index={index}
                  />
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

export default Projects

const ProjectsFilters = props => (
  <StaticQuery
    query={graphql`
      {
        allWpTaxSector {
          nodes {
            id: databaseId
            name
            slug
          }
        }
      }
    `}
    render={data => {
      let {
        allWpTaxSector: { nodes: posts },
      } = data
      // console.log(props)
      return (
        <>
          <ul className="filters d-flex list-inline justify-content-center">
            <li className="featured">
              <button
                data-filter="featured"
                className={
                  props.selectedFilter === "featured" ? "active" : null
                }
                onClick={() => {
                  props.onFilterChange("featured")
                }}
              >
                All
              </button>
            </li>
            {!!posts &&
              posts.map(post => {
                return (
                  <li key={post.id} className={post.slug}>
                    <button
                      data-filter={post.slug}
                      className={
                        props.selectedFilter === post.slug ? "active" : null
                      }
                      onClick={() => {
                        props.onFilterChange(post.slug)
                      }}
                    >
                      {parse(post.name)}
                    </button>
                  </li>
                )
              })}
          </ul>
        </>
      )
    }}
  ></StaticQuery>
)
